import { Box, Grid2, Typography, Paper } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const Header = () => {
    const navigate = useNavigate();
    const handleNav = (screen) => {
        navigate(screen)
    }
    return (
        <Paper elevation={2} sx={{px:4, py:2}}>
            <Grid2 container>
                <Grid2 size={{}}>
                    <img onClick={() => navigate('/')} src='/images/logo.png' width={64} height={46} />
                </Grid2>
                <Grid2 size={{}} className='hvCenter'>
                    <Typography sx={{cursor:'pointer'}} onClick={() => navigate('/')} variant='h5'>BELLS</Typography>
                </Grid2>
            </Grid2>
        </Paper>
    )
}

export default Header;
