import { Box, Button, Divider, Grid2, Typography } from '@mui/material';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AboutUs = () => {
    return (
        <>
            <Header />
            <Box sx={{ padding: 4, background: 'linear-gradient(to left bottom, #ff6766, #4a0505)', }}>
                <Grid2 container>
                <Grid2 size={{xs:12, md:6}} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant='h3' color='#FFF'>
                                Welcome to Bells – Redefining Investments for a New Era
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color='#FFF' variant='h6'>
                                Our mission is to create a reliable and innovative investment platform that empowers everyone to achieve financial growth with ease.
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{xs:12, md:6}} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <img src='/images/banner.png' height={600} />
                    </Grid2>
                </Grid2>
            </Box>
            <Box sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant='h3' align='center'>How it started?</Typography>
            </Box>
            <Box sx={{ padding: 4, marginTop: 4 }}>
                <Typography align='justify' sx={{marginBottom:4}}>
                    As a young student in college, I was eager to make money. During my time there, I came across the concepts of investing and trading, which grabbed my interest.
                </Typography>
                <Typography align='justify' sx={{marginBottom:4}}>
                    My journey began with stock market investments, where I encountered several challenges, such as pump-and-dump schemes, low trading volume, and the impact of news events. These issues made the stock market an unsuitable option for me and many other new investors.
                </Typography>
                <Typography align='justify' sx={{marginBottom:4}}>
                    I then transitioned to cryptocurrency, only to face similar problems, along with the added risk of scams, which were even worse than those in the stock market. In 2022, the rise of NFTs caught my attention, but I soon realized that, like many others, I was suffering losses as these assets turned out to be worthless.
                </Typography>
                <Typography align='justify' sx={{marginBottom:4}}>
                    In my search for better investment opportunities, I noticed that most platforms have a high percentage of users who lose their entire savings. This motivated me to create an initiative aimed at helping people build wealth. I spent over a year researching various investment platforms and identified the shortcomings of existing options. With this knowledge, I developed my app, designed to be the best place for investing, where users can earn guaranteed profits—100% guaranteed.
                </Typography>
            </Box>
            <Grid2 container sx={{ padding: 4, marginTop: 4 }} spacing={4}>
                <Grid2 size={12}>
                    <Typography variant='caption'>
                        Disclaimer: Investment in securities market are subject to market risks, read all the related documents carefully before investing.
                    </Typography>
                </Grid2>
            </Grid2>
            <Footer />
        </>
    )
}

export default AboutUs;
