import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from "@mui/material";

const CustomCard = (props) => {
    return (
        <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
                sx={{ height: 200 }}
                image={props.image}
                title={props.title}
            />
            <CardContent>
                <Typography align='center' gutterBottom variant="h5" component="div">
                    {props.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {props.description}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default CustomCard