import { Box, Grid2, Typography } from "@mui/material"

const StepsCard = (props) => {
    return (
        <Grid2 size={12} spacing={4}>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                {props.icon}
            </Box>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <Typography variant='h6'>{props.title}</Typography>
            </Box>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <Typography>{props.description}</Typography>
            </Box>
        </Grid2>
    )
}

export default StepsCard