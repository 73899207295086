import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutsUs";

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
