import { Box, Button, Divider, Grid2, Typography } from '@mui/material';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomCard from '../components/CustomCard';
import StepsCard from '../components/StepsCard';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ExploreIcon from '@mui/icons-material/Explore';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import ShieldIcon from '@mui/icons-material/Shield';

const Home = () => {
    return (
        <>
            <Header />
            <Box sx={{ padding: 4, background: 'linear-gradient(to left bottom, #ff6766, #4a0505)', }}>
                <Grid2 container>
                    <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h3' color='#FFF'>
                            Bell is the worlds first trading platform of youtubers coin where you can trade youtubers coin.
                        </Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <img src='/images/banner.png' width={'70%'} />
                    </Grid2>
                </Grid2>
            </Box>
            <Box sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant='h3' align='center'>Why Choose Us?</Typography>
            </Box>
            <Grid2 container sx={{ padding: 4, marginTop: 4 }} spacing={4}>
                <Grid2 size={{ lg: 4, xs: 12, md: 6, sm: 6 }} spacing={4}>
                    <CustomCard image="/images/fast-kyc.png" title="FAST KYC" description="ensure a robust and efficient identity verification process, enabling your KYC to be completed within hours of registration while maintaining the highest standards of compliance and security." />
                </Grid2>
                <Grid2 size={{ lg: 4, xs: 12, md: 6, sm: 6 }} spacing={4}>
                    <CustomCard image="/images/security.png" title="Top-Notch Security" description="At Bells, we prioritize your safety by implementing top-notch security measures. Our commitment includes regular security audits to maintain a highly secure and reliable trading platform." />
                </Grid2>
                <Grid2 size={{ lg: 4, xs: 12, md: 6, sm: 6 }} spacing={4}>
                    <CustomCard image="/images/anti-manipulation.png" title="Anti-Manipulation Mechanism" description="It eliminates price manipulation by automatically adjusting prices based on subscriber growth. Price changes are fully driven by the API, with no human involvement in the process, ensuring a fair and transparent trading environment." />
                </Grid2>
                <Grid2 size={{ lg: 4, xs: 12, md: 6, sm: 6 }} spacing={4}>
                    <CustomCard image="/images/profit.png" title="Subscriber Growth = Profits" description="We list only channels with a high subscriber count, ensuring consistent growth. As subscriber numbers typically increase over time, there is a low risk of losing funds, leading to stable, high-profit opportunities." />
                </Grid2>
                <Grid2 size={{ lg: 4, xs: 12, md: 6, sm: 6 }} spacing={4}>
                    <CustomCard image="/images/trustworthy.png" title="Trustworthy" description="In the event of a channel being hacked, trading is temporarily paused until the issue is resolved, ensuring security. If the channel is deleted, coins from a new channel or a channel with the same subscriber count will be added to your portfolio, maintaining the integrity of your investments." />
                </Grid2>
                <Grid2 size={{ lg: 4, xs: 12, md: 6, sm: 6 }} spacing={4}>
                    <CustomCard image="/images/stock-volume.png" title="Increase in stock volume" description="The coins listed on our platform have a limited supply of up to 5 lakh, which enhances market volatility and accelerates trade execution, enabling faster transactions." />
                </Grid2>
            </Grid2>
            <Box sx={{ padding: 4, marginTop: 4 }}>
                <Divider />
            </Box>
            <Grid2 container sx={{ paddingLeft: 4, marginTop: 4, background: '#7C7496' }} spacing={4}>
                <Grid2 size={{ lg: 6, xs: 12, md: 6, sm: 6 }} sx={{ padding: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography color='white' variant='h3'>Fed up with researching stock data to find where to invest?</Typography>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <Typography color='white'>Spending hours on research and losing money in the stock market?</Typography>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <Typography color='white'>Make hassle-free investments on our platform!</Typography>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <Typography color='white'>Invest in YouTube creator NFTs and start earning good profits within just one month.</Typography>
                    </Box>
                </Grid2>
                <Grid2 size={{ lg: 6, xs: 12, md: 6, sm: 6 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img src='/images/fedup.png' width={"75%"} />
                    </Box>
                </Grid2>

            </Grid2>
            <Grid2 container sx={{ paddingLeft: 4, background: '#F8DAE7' }} spacing={4}>
                <Grid2 size={{ lg: 6, xs: 12, md: 6, sm: 6 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <img src='/images/easy.png' width={"75%"} />
                    </Box>
                </Grid2>
                <Grid2 size={{ lg: 6, xs: 12, md: 6, sm: 6 }} sx={{ padding: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant='h3'>Let’s make your investment journey easy!</Typography>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <Typography >Things to consider before selecting an NFT</Typography>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <ul>
                            <Box> <li><Typography>⁠Volume of the NFT coin  </Typography></li></Box>
                            <Box>  <li><Typography>⁠Channel engagement</Typography> </li></Box>
                            <Box>  <li><Typography>⁠The hype around the YouTuber</Typography>  </li></Box>
                            <Box>  <li><Typography>Whether the YouTuber is active on their channel or other social media platforms.</Typography></li></Box>
                        </ul>
                    </Box>

                </Grid2>


            </Grid2>
            <Box sx={{ padding: 4, marginTop: 4 }}>
                <Divider />
            </Box>
            <Box sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant='h3' align='center'>How it works?</Typography>
            </Box>
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant='h6' align='center'>Want to invest in YouTube NFTs?
                    Wondering how to get started?
                    It's easy! Follow these 5 simple steps:</Typography>
            </Box>
            <Grid2 container sx={{ padding: 4, marginTop: 4 }} spacing={4}>
                <Grid2 size={1} spacing={4}></Grid2>
                <Grid2 size={{ xs: 12, sm: 4, lg: 2 }} spacing={4} sx={{ borderRadius: 2, padding: 2, background: '#F08080' }}>
                    <StepsCard icon={<HowToRegIcon />} title="Sign up and verify your account" description="Create an account on our platform by completing a simple registration process and submitting the required documents for quick verification." />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 4, lg: 2 }} spacing={4} sx={{ borderRadius: 2, padding: 2, background: '#FFE4E1' }}>
                    <StepsCard icon={<ExploreIcon />} title="Explore and select your favorite YouTuber NFTs" description="Browse our curated list of YouTuber NFTs. Choose the ones that align with your investment goals." />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 4, lg: 2 }} spacing={4} sx={{ borderRadius: 2, padding: 2, background: '#FFDAB9' }}>
                    <StepsCard icon={<TipsAndUpdatesIcon />} title="Personalize your watchlist" description="Add your favorite YouTubers to a personalized watchlist to keep track of their NFTs and stay updated on trends." />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 4, lg: 2 }} spacing={4} sx={{ borderRadius: 2, padding: 2, background: '#FFF5EE' }}>
                    <StepsCard icon={<CurrencyBitcoinIcon />} title="Buy your chosen NFTs with ease" description={`Click the "Buy" button on the NFTs you want to invest in and monitor their performance in your order list.`} />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 4, lg: 2 }} spacing={4} sx={{ borderRadius: 2, padding: 2, background: '#FFF0F5' }}>
                    <StepsCard icon={<ShieldIcon />} title="Secure your investment and track your portfolio" description={`Make your payment and manage your purchased NFTs seamlessly in your portfolio.`} />
                </Grid2>
                <Grid2 size={1} spacing={4}></Grid2>
            </Grid2>
            <Grid2 container sx={{ padding: 4, marginTop: 4 }} spacing={4}>
                <Grid2 size={12}>
                    <Typography variant='caption'>
                        Disclaimer: Investment in securities market are subject to market risks, read all the related documents carefully before investing.
                    </Typography>
                </Grid2>
            </Grid2>
            <Footer />
        </>
    )
}

export default Home;
